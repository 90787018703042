<template>
  <main>
    <package-header title="Info &amp; History" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col-lg-8">

          <!-- Tabs navigation -->
          <ul class="nav nav-tabs mb-4" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" href="javascript:void(0)">
                Info
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)">
                History
              </a>
            </li>
          </ul>

          <!-- Tab content -->
          <div class="card mb-4">
            <div class="card-header bg-light"><h3 class="mb-0">Status</h3></div>
            <div class="card-body">
              <strong class="text-muted">
                Unsubmitted
                <a class="collapse-toggle" href="#collapseExample" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                </a>
              </strong>
              <div class="collapse" id="collapseExample">
                <div class="card card-body bg-light mt-2">
                  Help text
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header bg-light"><h3 class="mb-0">Details</h3></div>
            <div class="card-body">
              <dl class="row mb-0">
                <dt class="col-sm-3">File Reference:</dt>
                <dd class="col-sm-9">12764 Smith <a class="pl-1" href="javascript:void(0)">Edit</a></dd>

                <dt class="col-sm-3">Package ID:</dt>
                <dd class="col-sm-9">OGJO4000</dd>

                <dt class="col-sm-3">Description:</dt>
                <dd class="col-sm-9">Road closure and transfer of title from City of Kimberly to applicant <a class="pl-1" href="javascript:void(0)">Edit</a></dd>

                <dt class="col-sm-3 text-truncate">
                  Creator
                  <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                    <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  </a>
                </dt>
                <dd class="col-sm-9">Pam Jones</dd>
              </dl>
            </div>
          </div>

          <button class="btn btn-danger">Delete Package</button>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Searches &amp; STCs"></help-panel>
  </main>
</template>

<script>

var filters = {
  active: function (tableItems) {
    return tableItems.filter(function (tableItem) {
      return tableItem.selected
    })
  }
}

import PackageHeader from '../../components/PackageHeader.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
    }
  }
}
</script>

